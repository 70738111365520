import { render, staticRenderFns } from "./zhengwu.vue?vue&type=template&id=59990357&scoped=true&"
var script = {}
import style0 from "./zhengwu.vue?vue&type=style&index=0&id=59990357&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59990357",
  null
  
)

export default component.exports